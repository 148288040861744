import React from 'react';

const BottomMiniMenu = ({ isVisible, onClose, tgData, openCs }) => {


  return (
    <div className={`bottom-mini-menu ${isVisible ? 'bottom-mini-menu--visible' : ''}`}>
      <div className="bottom-mini-menu__content">
        <div className="bottom-mini-menu__title">
          <img src="/static/images/miniMenu/chat.png" alt="Chat" />
          <p>Liên Hệ 24/7</p>
        </div>
        <div
          onClick={openCs}
          className="bottom-mini-menu__item"
        >
          <img src="/static/images/miniMenu/livechat.png" className='icon' alt="Live Chat" />
          <p>Live Chat</p>
        </div>
        <a
          className="bottom-mini-menu__item"
          href="mailto:cs@jbovn.com"
          onClick={onClose}
        >
          <img src="/static/images/miniMenu/email.png" className='icon' alt="Email" />
          <p>cs@jbovn.com</p>
        </a>
        <a
          className="bottom-mini-menu__item"
          href="tel:(+84) 2844581344"
          onClick={onClose}
        >
          <img src="/static/images/miniMenu/hotline.png" className='icon_two' alt="HotLine" />
          <p>HotLine (+84) 2844581344</p>
        </a>
        <a
          className="bottom-mini-menu__item"
          href={tgData.tgUrl}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onClose}
        >
          <img src="/static/images/miniMenu/telegram.png" className='icon_two' alt="Telegram" />
          <p>{tgData.tgUsername}</p>
        </a>
      </div>
    </div>
  );
};

export default BottomMiniMenu;
