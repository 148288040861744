import React from "react";
import { message, Modal, Spin } from "antd";
import moment from "moment";
import Toast from '@/Toast';
const STATUS_LOADING = 0; // No image loaded
const STATUS_READY = 1; // Image loaded, ready to slide
const STATUS_MATCH = 2; // Puzzle matched successfully
const STATUS_ERROR = 3; // Puzzle match failed

class _FpCaptcha extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imgSrc: "",
            showInfo: false,
            isMovable: false,
            startX: 0, // Start x position for mouse/touch
            startY: 0, // Start y position for mouse/touch
            currX: 0, // Current x position for the sliding block
            currY: 0, // Current y position for the sliding block
            status: STATUS_LOADING,
            verifyStatue: "0", //(0: Not verified, 1: Success, 2: Failure)
        };
        this.canvas = null;
        this.ctx = null;
        this.img = null;
        this.options = {
            col: 10,
            row: 2,
            chartUri: "",
            shuffleMatrix: [],
        };
        this.allowRefresh = true;
    }

    componentDidUpdate(prevProps) {
        if (
            !!this.props.chartUri &&
            prevProps.chartUri !== this.props.chartUri
        ) {
            this.setState(
                {
                    chartUri: this.props.chartUri,
                    keyUri: this.props.keyUri,
                    shuffleMatrix: this.props.shuffleMatrix,
                },
                () => {
                    let { chartUri, keyUri, shuffleMatrix } = this.state;
                    this.createCanvas();
                    this.renderImage({ chartUri, keyUri, shuffleMatrix });
                }
            );
        }
        if (prevProps.visible !== this.props.visible && !this.props.visible) {
            this.onReset();
        }
    }

    createCanvas() {
        this.canvas = document.createElement("canvas");
        this.ctx = this.canvas.getContext("2d");
        this.canvas.width = "300";
        this.canvas.height = "150";
    }

    async renderImage(options) {
        this.options = { ...this.options, ...options };
        try {
            await this.loadImage();
            this.splitImg();
        } catch (e) {
            console.log(e);
        }
    }

    loadImage = () => {
        return new Promise((resolve, reject) => {
            var image = new Image();
            image.crossOrigin = "";
            image.src = this.options.chartUri;
            image.onload = (e) => {
                this.img = image;
                resolve(e);
            };
            image.onerror = (e) => {
                reject(e);
            };
        });
    };

    splitImg() {
        let list = [];
        for (let y = 0; y < this.options.row; y++) {
            for (let x = 0; x < this.options.col; x++) {
                let simpleWidth = parseInt(this.img.width / this.options.col);
                let simpleHeight = parseInt(this.img.height / this.options.row);
                list.push({
                    x: x * simpleWidth,
                    y: y * simpleHeight,
                    width: simpleWidth,
                    height: simpleHeight,
                });
            }
        }
        let sortList = this.sortList(list);
        this.setImgBase64(sortList);
    }

    sortList(list) {
        list.forEach((element, i) => {
            element.index = this.options.shuffleMatrix[i];
        });
        let newlist = list.sort((param1, param2) => {
            return param1.index - param2.index;
        });
        return newlist;
    }

    async setImgBase64(list) {
        try {
            await this.drawImg(list);
            const base64 = this.canvas.toDataURL();
            this.setState({
                imgSrc: base64,
                status: STATUS_READY,
            });
            this.props.setLoading(false);
        } catch (e) {
            console.log(e);
        }
    }

    drawImg(list) {
        if (this.ctx) {
            this.ctx.clearRect(0, 0, 300, 150);
        }
        for (let k = 0; k < list.length; k++) {
            let object = list[k];
            let dx, dy;
            if (k > 9) {
                dx = (k - 10) * 30;
                dy = 75;
            } else {
                dx = (k - 0) * 30;
                dy = 0;
            }
            if (this.ctx) {
                this.ctx.drawImage(
                    this.img,
                    object.x,
                    object.y,
                    object.width,
                    object.height,
                    dx,
                    dy,
                    object.width,
                    object.height
                );
            }
        }
    }

    // Utility function to throttle the event updates
    throttle = (func, delay) => {
        let lastCall = 0;
        return (...args) => {
            const now = new Date().getTime();
            if (now - lastCall < delay) {
                return;
            }
            lastCall = now;
            return func(...args);
        };
    };

    // Updated handleDragMove with throttling
    handleDragMove = this.throttle((e) => {
        e.preventDefault();
        let eventX = e.clientX || e.touches[0].clientX; // Handle touch for mobile
        let eventY = e.clientY || e.touches[0].clientY; // Handle touch for mobile

        if (this.state.status !== STATUS_READY || !this.state.isMovable) {
            return;
        }
        const distanceX = eventX - this.state.startX;
        let currX = distanceX;

        const distanceY = eventY - this.state.startY;
        let currY = distanceY;

        const minX = 0;
        const minY = 0;
        const maxX = 300 - 60;
        const maxY = 150 - 60;

        currX = currX < minX ? 0 : currX > maxX ? maxX : currX;
        currY = currY < minY ? 0 : currY > maxY ? maxY : currY;

        // Use throttle to limit how frequently state updates
        this.setState({ currX, currY });
    }, 16); // Throttle the event to 60fps (1000ms / 60frames = ~16ms)

    // Updated onMouseDownEvent to properly handle touch events and set movement flags
    onMouseDownEvent = (e) => {
        e.preventDefault();
        let eventX = e.clientX || e.touches[0].clientX; // Handle touch for mobile
        let eventY = e.clientY || e.touches[0].clientY; // Handle touch for mobile

        if (this.state.status !== STATUS_READY) {
            return;
        }

        const startTime = moment();

        this.setState(
            {
                isMovable: true,
                startX: eventX,
                startY: eventY,
                startTime: startTime,
            },
            () => {
                document.addEventListener('mousemove', this.handleDragMove);  // For desktop
                document.addEventListener('touchmove', this.handleDragMove); // For mobile
                document.addEventListener('mouseup', this.handleDragEnd);  // For desktop
                document.addEventListener('touchend', this.handleDragEnd); // For mobile
            }
        );
    };

    handleDragEnd = (e) => {
        e.preventDefault();

        this.setState({
            isMovable: false,
        });

        document.removeEventListener('mousemove', this.handleDragMove);
        document.removeEventListener('touchmove', this.handleDragMove);
        document.removeEventListener('mouseup', this.handleDragEnd);
        document.removeEventListener('touchend', this.handleDragEnd);

        const nowTime = moment();
        const cost = new Date(nowTime).getTime() - new Date(this.state.startTime).getTime(); // Calculate time spent

        this.props.judgement(
            {
                x: this.state.currX,
                y: this.state.currY,
                cost: cost,
            },
            this.judgeResult
        );
    };

    judgeResult = (code) => {
        if (["10001", "10002", "11001"].includes(String(code))) {
            this.setState({ verifyStatue: "1" });
            setTimeout(() => {
                this.props.onMatch(this.props.challengeUuid);
                this.setState({ verifyStatue: "0" });
            }, 3000);
        } else {
            //this.setState({ verifyStatue: "2" });
             // Determine if the view is not desktop
             const isMobile = window.innerWidth <= 1024;
             if (isMobile) {
                 // Inject custom styles for the Toast
                 const customStyles = `
                     .am-toast > span {
                         max-width: 100% !important;
                     }
                     .am-toast-notice-content .am-toast-text {
                         border: none !important;
                         box-shadow: none !important;
                         background-color: #F5F5F5 !important;
                         border-radius: 4px !important;
                         min-width: 2.5rem !important;
                         line-height: normal !important;
                         max-height: 3rem !important;
                         color: #1A1A1A !important;
                         height: 44px !important;
                         font-size: 14px !important;
                         padding-top: 0.05rem !important;
                         padding-left: 10px !important; 
                         padding-right: 10px !important;
                     }
                 `;
                 const styleTag = document.createElement('style');
                 styleTag.id = 'custom-toast-styles';
                 styleTag.innerHTML = customStyles;
                 document.head.appendChild(styleTag);
 
                 // Optionally remove the style after the Toast duration to prevent buildup
                 setTimeout(() => {
                     const existingStyleTag = document.getElementById('custom-toast-styles');
                     if (existingStyleTag) {
                         existingStyleTag.remove();
                     }
                 }, 1500); // Adjust the timeout to match your Toast duration
             }
            const alert = () => (
                <div
                    style={{
                        borderRadius: !isMobile && '3px',
                        fontSize: !isMobile && '15px',
                        color: !isMobile && 'black',
                        backgroundColor: !isMobile && 'white',
                        width: !isMobile && 'auto',
                        padding: !isMobile && '10px 20px'
                    }}
                >
                    <p style={{paddingTop:isMobile ? '0.7rem':''}}>
                        <img
                            style={{ bottom: '-5px', position: 'relative', paddingRight: '10px' }}
                            src={`/static/images/error_small.png`}
                            alt="error"
                        />
                       Xác Thực Lỗi, Vui Lòng Thử Lại
                    </p>
                </div>
            );
            Toast.nonInfo(alert(), 1.5);

            const timer = setTimeout(() => {
                this.allowRefresh = false;
                this.resetPosition();
                if (code == "63403") {
                    this.props.onReload();
                }
                clearTimeout(timer);
            }, 3000);
        }
    };

    resetPosition = () => {
        this.setState({
            currX: 0,
            currY: 0,
            status: STATUS_READY,
            verifyStatue: "0",
        });
    };

    onRefresh = () => {
        if (!this.allowRefresh) {
            //message.error("操作过于频繁，请稍后再试。", 3);
            const alert = () => (
                <div
                    style={{
                        borderRadius: '3px',
                        fontSize: '15px',
                        color: 'black',
                        backgroundColor: 'white',
                        width: 'auto',
                        padding: '10px 20px'
                    }}
                >
                    <p>
                        <img
                            style={{ bottom: '-5px', position: 'relative', paddingRight: '10px' }}
                            src={`/static/images/error_small.png`}
                            alt="error"
                        />
                      Thao tác này quá thường xuyên, vui lòng thử lại sau.   
                    </p>
                </div>
            );
            Toast.nonInfo(alert(), 1.5);
            setTimeout(() => {
                this.allowRefresh = true;
            }, 3000);
            return;
        }
        this.allowRefresh = false;
        this.onReset();
        this.props.onReload();
    };

    onReset = () => {
        this.canvas = null;
        this.ctx = null;
        this.img = null;
        this.setState({
            imgSrc: "",
            isMovable: false,
            startX: 0,
            startY: 0,
            currX: 0,
            currY: 0,
            status: STATUS_LOADING,
            showInfo: false,
        });
    };

    onShowInfo = () => {
        this.setState({showInfo:!this.state.showInfo})
    }

    render() {
        const { imgSrc, showInfo, verifyStatue, keyUri } = this.state;
        const { visible, loading, currentLocale  } = this.props;
        console.log("currentLocale",currentLocale)
        return (
            <Modal
                footer={null}
                visible={visible}
                maskClosable={false}
                centered={true}
                onCancel={() => {
                    this.props.setVisible(false);
                }}
                className="fpcaptch-modal"
                zIndex={100}
                destroyOnClose={true}
            >
                <Spin spinning={loading && !imgSrc} tip={"Tải vui lòng đợi..."}>
                    <div className="fpcontent">
                    <p style={{fontSize:"11.5px",marginBottom:'0.5rem',width:'92%'}}>{currentLocale && currentLocale.info}</p>
                        <div
                            className="fpSection"
                            style={{
                                background: imgSrc
                                    ? `url(${imgSrc}) no-repeat`
                                    : "#eee",
                            }}
                        >
                            <div
                                className="moveElement"
                                id="moveElement"
                                style={{
                                    background:
                                        imgSrc && keyUri
                                            ? `url(${keyUri}) no-repeat`
                                            : "#eee",
                                    left: this.state.currX + "px",
                                    top: this.state.currY + "px",
                                }}
                                onMouseDown={this.onMouseDownEvent}
                                onTouchStart={this.onMouseDownEvent} // Add touch event
                            ></div>
                                    <div
                                    className="info"
                                    style={{
                                        display: showInfo ? "block" : "none",
                                    }}
                                >
                                    <img
                                        src={'/static/images/icon-closed.png'}
                                        onClick={this.onShowInfo}
                                    />
                                    <p>
                                        {"Vui lòng nhấn giữ mảnh ghép và trượt mảnh ghép vào vị trí chính xác còn thiếu để hoàn tất. Đã gửi đáp án của bạn, vui lòng đợi kết quả xác minh."}
                                    </p>
                                </div>
                        </div>
                
                        {/* <div
                            className="verifyFail"
                            style={{
                                display:
                                    verifyStatue === "2" ? "block" : "none",
                            }}
                        >
                            <span>{"失败。 请再试一次"}</span>
                        </div> */}
                        <div className="fpFooter">
                            <img
                                src={`/static/images/icon-refresh.svg`}
                                onClick={this.onRefresh}
                            />
                            <img
                                src={`/static/images/icon-info.svg`}
                                onClick={this.onShowInfo}
                            />
                        </div>
                    </div>
                </Spin>
            </Modal>
        );
    }
}

export default _FpCaptcha;
