import React, {useState, useEffect} from "react";

const MiniMenu = ({ toggleBottomMenu, tgData, openCs }) => {

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth <= 1080);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  
  return (
    <div className="mini-menu">
      <div 
        className="mini-menu__outer" 
        onClick={isSmallScreen ? toggleBottomMenu : null}>
          <img src="/static/images/miniMenu/chat.png" />
          <div className="mini-menu__rotate">Liên Hệ 24/7</div>
      </div>
      <div className="mini-menu__inner">
        <ul>
          <li onClick={openCs}>
            <div className="mini-menu__item">
              <img src="/static/images/miniMenu/livechat.png" className="icon" alt="Live Chat" />
              <p className="hoverGreen">Live Chat</p>
            </div>
          </li>

          <li>
            <a href="mailto: cs@jbovn.com">
              <div className="mini-menu__item">
                <img src="/static/images/miniMenu/email.png" className="icon" alt="Email" />
                <p className="hoverGreen">cs@jbovn.com</p>
              </div>
            </a>
          </li>
          <li>
            <div className="mini-menu__item">
              <img src="/static/images/miniMenu/hotline.png" className="icon_two" alt="Live Chat" />
              <div className="hoverGreen hot-line">
                <p>HotLine</p>
                <p className="hoverGreen">(+84) 2844581344</p>
              </div>
            </div>
          </li>
          <li>
            <a className="telegram hoverGreen" href={tgData.tgUrl} target="_blank" rel="noopener noreferrer">
              <div className="mini-menu__item">
			          <img src="/static/images/miniMenu/telegram.png" className="icon_two" alt="Live Chat" />
                <div className="hot-line">
                  <p>Telegram</p>
                  <p>{tgData.tgUsername}</p>
                </div>
              </div>
              <img src="/static/images/miniMenu/arrow-down.png" className="arrowTrans" style={{ marginLeft: "20%" }} alt="Arrow Down" />
            </a>
            <div className="showQR" style={{ backgroundImage: `url(${tgData.tgQrcode})` }}>
                <div className="showQR-content">
                    {/* Placeholder for QR Code */}
                </div>
                <div className="showQR-text">
                    Quét mã QR ngay để trò chuyện với CS JBO!
                </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MiniMenu;
